import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    "& a": {
      color: '#3f51b5',
    },
  },
}))

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const classes = useStyles()
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.meta} />
      <Container maxWidth="md" className={classes.container}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        meta
      }
    }
  }
`
